.responsive-alert {
  width: 375px;
  margin: 0 auto;
  padding: 1rem;
}
.responsive-alert .logo-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
}
.responsive-alert .logo-sidebar h1 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.responsive-alert .logo-sidebar img {
  width: 65px;
  height: 65px;
}
.responsive-alert .alert__text {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 2rem;
}