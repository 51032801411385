.login {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.login .login__container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 8px;
  padding: 50px;
}
.login .login__container .login__logo {
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
}
.login .login__container h1 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
}
.login .login__container p {
  font-weight: 400;
  font-size: 10px;
  color: #9b9b9b;
}
.login .login__container p.login__validation {
  color: #ff4530;
}
.login .login__container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 25px;
}
.login .login__container form .login__input {
  width: 350px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 8px;
  border: 1px solid #eceef2;
  border-radius: 8px;
  outline: none;
  position: relative;
}
.login .login__container form .login__input img {
  padding: 0 15px;
}
.login .login__container form .login__input input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.login .login__container form .login__input input::-webkit-input-placeholder {
  color: #9b9b9b;
}
.login .login__container form .login__input input::-moz-placeholder {
  color: #9b9b9b;
}
.login .login__container form .login__input input:-ms-input-placeholder {
  color: #9b9b9b;
}
.login .login__container form .login__input input::-ms-input-placeholder {
  color: #9b9b9b;
}
.login .login__container form .login__input input::placeholder {
  color: #9b9b9b;
}
.login .login__container form .login__input:focus-within {
  border: 1px solid #277ffe;
}
.login .login__container form .login__button {
  width: 350px;
  height: 50px;
  margin-top: 8px;
  margin-bottom: 30px;
}
.login .login__container form .login__button button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: #277ffe;
  color: white;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.login .login__container form .login__button button:hover {
  background-color: #1e6be5;
}
.login .login__container form .eye__icon {
  cursor: pointer;
}
.login .login__container .login__forgotPassword {
  font-weight: 400;
  font-size: 10px;
  color: #9b9b9b;
}
.login .login__container .login__forgotPassword a {
  text-decoration: none;
  color: #277ffe;
}

.lds-spinner {
  color: black;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}

.lds-spinner div {
  -webkit-transform-origin: 12px 12px;
          transform-origin: 12px 12px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: black;
}

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}