.no-internet .content {
  width: 1215px;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
}
.no-internet .content img {
  width: 110px;
  height: 110px;
}
.no-internet .content h1 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
.no-internet .content p {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.no-internet .content button {
  width: 120px;
  height: 32px;
  border-radius: 5px;
  margin-top: 1rem;
}