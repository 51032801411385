.add-karyawan {
  width: 80%;
}
.add-karyawan .unhide {
  cursor: pointer;
}
.add-karyawan .navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #ebf0fe;
  padding: 1rem 2.5rem;
}
.add-karyawan .navigation div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.add-karyawan .navigation div .wrapper-img {
  width: 24px;
  height: 24px;
}
.add-karyawan .navigation div .wrapper-img img {
  cursor: pointer;
}
.add-karyawan .navigation div h1 {
  font-size: 24px;
  font-weight: 500;
  margin-left: 2rem;
}
.add-karyawan .navigation .btn-submit {
  width: 125px;
  height: 40px;
  background-color: #3661eb;
  border-radius: 8px;
  border: none;
  outline: none;
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.add-karyawan .navigation .btn-submit:disabled {
  opacity: 1;
}
.add-karyawan .form-foto-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.add-karyawan .form {
  width: 65%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 2rem 2.5rem;
}
.add-karyawan .form .validator-text {
  color: #ff4530;
  font-size: 12px;
  margin: 0.25rem 0rem 0rem;
}
.add-karyawan .form label {
  font-size: 18px;
  font-weight: 500;
  color: black;
  margin-bottom: 1rem;
}
.add-karyawan .form input {
  height: 45px;
  border: 2px solid #eceef2;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  color: #5a6474;
  padding: 0 1rem;
}
.add-karyawan .form input:focus {
  border: 2px solid #3661eb;
}
.add-karyawan .form input::-webkit-input-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form input::-moz-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form input:-ms-input-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form input::-ms-input-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form input::placeholder {
  color: #9b9b9b;
}
.add-karyawan .form .nama,
.add-karyawan .form .niy,
.add-karyawan .form .email,
.add-karyawan .form .no-hp,
.add-karyawan .form .jabatan,
.add-karyawan .form .gender,
.add-karyawan .form .alamat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.add-karyawan .form .nama,
.add-karyawan .form .email,
.add-karyawan .form .no-hp,
.add-karyawan .form .jabatan-gender {
  margin-bottom: 1rem;
}
.add-karyawan .form .nama img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 1rem;
  position: absolute;
  right: 0;
  -webkit-transform: translate(-50%, 225%);
          transform: translate(-50%, 225%);
  cursor: pointer;
}
.add-karyawan .form .niy {
  margin-bottom: 1.5rem;
}
.add-karyawan .form .niy .validator-text.pwd {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
}
.add-karyawan .form .niy img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.add-karyawan .form .jabatan-gender {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.add-karyawan .form .jabatan-gender .select select {
  width: 100%;
  height: 45px;
  border: 2px solid #eceef2;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  color: #5a6474;
  padding: 0 1rem;
}
.add-karyawan .form .jabatan-gender .select select::-webkit-input-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form .jabatan-gender .select select::-moz-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form .jabatan-gender .select select:-ms-input-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form .jabatan-gender .select select::-ms-input-placeholder {
  color: #9b9b9b;
}
.add-karyawan .form .jabatan-gender .select select::placeholder {
  color: #9b9b9b;
}
.add-karyawan .form .jabatan-gender .select select:focus {
  border: 2px solid #3661eb;
}
.add-karyawan .form .jabatan-gender .select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../assets/icons/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}
.add-karyawan .form .jabatan-gender .jabatan {
  width: 100%;
}
.add-karyawan .form-file-upload {
  max-width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  position: relative;
  padding-top: 2rem;
}
.add-karyawan .form-file-upload h1:nth-child(1) {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 1rem;
}
.add-karyawan .form-file-upload .wrap-text h1 {
  font-size: 14px;
  font-weight: 500;
}
.add-karyawan #input-file-upload {
  display: none;
}
.add-karyawan #label-file-upload {
  height: 100%;
  height: 193px;
  width: 386px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-width: 1px;
  border-radius: 8px;
  border-style: dashed;
  border-color: #3661eb;
  background-color: #fafafa;
  padding: 1rem 0;
  cursor: pointer;
}
.add-karyawan .upload-button {
  cursor: pointer;
  font-size: 12px;
  border: none;
  background-color: #fafafa;
  color: #9b9b9b;
  line-height: 24px;
}
.add-karyawan .upload-button:hover {
  text-decoration-line: underline;
}
.add-karyawan .preview__img {
  height: 100%;
  border-radius: 50%;
}
.add-karyawan #label-file-upload.drag-active {
  background-color: #ffffff;
}
.add-karyawan #drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.wrapper__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}
.wrapper__btn button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.wrapper__btn .edit__btn {
  background-color: #3661eb;
}
.wrapper__btn .ganti__btn {
  background-color: #3661eb;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
  -moz-appearance: textfield;
}

.bg-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.bg-modal .alert-modal {
  width: 350px;
  height: 225px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.bg-modal .alert-modal h1 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 1rem;
  margin-right: auto;
}
.bg-modal .alert-modal p {
  font-weight: 500;
  font-size: 14px;
  margin-right: auto;
}
.bg-modal .alert-modal div {
  margin-top: auto;
  background-color: #f9f9f9;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 1rem;
  padding: 0 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.bg-modal .alert-modal button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.bg-modal .alert-modal button:nth-child(1) {
  background-color: #ff4530;
}
.bg-modal .alert-modal button:nth-child(2) {
  background-color: #3661eb;
}

.bg__modal__cropper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.bg__modal__cropper .modal__cropper {
  width: 500px;
  height: 600px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.bg__modal__cropper .modal__cropper h1 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 1rem;
  margin-right: auto;
  padding-left: 1rem;
}
.bg__modal__cropper .modal__cropper .wrapper__btn {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: auto;
  padding: 0 1rem;
}
.bg__modal__cropper .modal__cropper .wrapper__btn button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.bg__modal__cropper .modal__cropper .wrapper__btn button:first-child {
  background-color: #ff4530;
}
.bg__modal__cropper .modal__cropper .wrapper__btn button:nth-child(2) {
  background-color: #3661eb;
}

.reactEasyCrop_Container {
  width: 100%;
  height: 80%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  -webkit-filter: none;
          filter: none;
  position: relative !important;
}