.nav {
  width: 225px;
  height: 100vh;
  border-right: 1px solid #ebf0fe;
  border-bottom: 1px solid #ebf0fe;
  position: sticky;
  top: 0;
}
.nav .active-sidebar {
  color: #3661eb;
}
.nav .active-sidebar .active.img {
  -webkit-filter: brightness(0) saturate(100%) invert(29%) sepia(95%) saturate(2816%) hue-rotate(221deg) brightness(95%) contrast(93%);
          filter: brightness(0) saturate(100%) invert(29%) sepia(95%) saturate(2816%) hue-rotate(221deg) brightness(95%) contrast(93%);
}
.nav .logo-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2rem 0;
}
.nav .logo-sidebar img {
  width: 108px;
  height: 108px;
}
.nav .logo-sidebar h1 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 1rem;
}
.nav ul li {
  list-style: none;
  margin-bottom: 1.5rem;
}
.nav ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: #9b9b9b;
  cursor: pointer;
}
.nav ul li a img {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}