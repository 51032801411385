.kehadiran {
  width: 1215px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kehadiran h1 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.kehadiran .search-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  background-color: white;
  padding: 1rem 1.5rem;
  border: 1px solid #ebf0fe;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 1rem;
}
.kehadiran .search-calendar .search {
  background-position: 5% 50%;
  background-size: 13px;
  width: 375px;
  height: 35px;
  padding: 0.5rem 2.5rem;
  outline: none;
  font-weight: 400;
  border-radius: 3px;
}
.kehadiran .search-calendar .search::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 10px;
}
.kehadiran .search-calendar .search::-moz-placeholder {
  font-weight: 400;
  font-size: 10px;
}
.kehadiran .search-calendar .search:-ms-input-placeholder {
  font-weight: 400;
  font-size: 10px;
}
.kehadiran .search-calendar .search::-ms-input-placeholder {
  font-weight: 400;
  font-size: 10px;
}
.kehadiran .search-calendar .search::placeholder {
  font-weight: 400;
  font-size: 10px;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 280px;
  height: 35px;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal img {
  width: 14px;
  height: 14px;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal .pilih-tanggal {
  width: 125px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #eceef2;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 3px;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal .pilih-tanggal .btn-pilih-tanggal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem;
  padding-right: 0;
  width: 100%;
  cursor: pointer;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal .pilih-tanggal .react-calendar {
  width: 300px;
  position: absolute;
  border: 1.5px solid #ebf0fe;
  padding: 1rem;
  border-radius: 7px;
  -webkit-transform: translate(25%, 10%);
          transform: translate(25%, 10%);
  z-index: 999;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal .pilih-tanggal .wrap-text {
  width: 100%;
  display: grid;
  place-items: center;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal .pilih-tanggal img {
  width: 13px;
  height: 13px;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal .pilih-tanggal p {
  font-size: 10px;
  color: #a6a6a6;
}
.kehadiran .search-calendar .wrapper-pilih-tanggal .pilih-tanggal .full-screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.kehadiran .search-calendar .btn-search {
  border: 1px solid #eceef2;
  width: 50px;
}
.kehadiran .tabbar-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  background-color: white;
  padding-right: 1.5rem;
  border: 1px solid #ebf0fe;
  border-top: none;
}
.kehadiran .tabbar-filter .filter-download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
  padding-left: 3rem;
}
.kehadiran table .row-img {
  padding: 0.5rem 0.75rem;
  cursor: default;
}
.kehadiran table .loading-table {
  position: absolute;
  width: 100% !important;
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
}
.kehadiran table .loading-table td {
  font-size: 14px;
}
.kehadiran table .loading::before {
  margin: 0;
}
.kehadiran table .th-name {
  width: 227px;
}
.kehadiran table .th-niy {
  width: 105px;
}
.kehadiran table .th-jabatan {
  width: 180px;
}
.kehadiran table .th-tanggal {
  width: 120px;
}
.kehadiran table .th-tanggal.izin {
  width: 175px;
}
.kehadiran table .th-waktu {
  width: 70px;
}
.kehadiran table .th-action {
  width: 109px;
  padding-left: 1rem;
}
.kehadiran table .td-jabatan {
  padding-right: 1rem;
}
.kehadiran table .wrapper__btn__detail {
  padding-left: 1rem;
}
.kehadiran table .wrapper__row__jam {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kehadiran .wrapper__table {
  height: auto;
}
.valid-masuk-pulang {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-right: 1rem;
}

.valid-masuk-pulang.izin {
  background-color: #ffd233;
}

.valid {
  background-color: #04d57d !important;
}

.gak-valid {
  background-color: #ff4530 !important;
}

.valid-text {
  color: #04d57d !important;
}

.gak-valid-text {
  color: #ff4530 !important;
}

.detail {
  width: 80%;
  border-right: 1px solid #eceef2;
  border-bottom: 1px solid #eceef2;
}
.detail .navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #eceef2;
  padding: 1rem 2.5rem;
}
.detail .navigation img {
  cursor: pointer;
}
.detail .navigation a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.detail .navigation h1 {
  font-size: 24px;
  font-weight: 500;
  margin-left: 2rem;
}
.detail .main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 3rem;
}
.detail .main .detail-masuk-keluar {
  width: 65%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4rem;
  padding: 2rem 2.5rem;
}
.detail .main .detail-masuk-keluar .masuk-keluar {
  width: 315px;
}
.detail .main .detail-masuk-keluar .masuk-keluar .jam-masuk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem;
}
.detail .main .detail-masuk-keluar .masuk-keluar .jam-masuk h3 {
  font-size: 18px;
  font-weight: 500;
}
.detail .main .detail-masuk-keluar .masuk-keluar .jam-masuk p {
  font-size: 12px;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #ebf0fe;
  border-radius: 10px;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .wrapper-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 372px;
  width: 315px;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .wrapper-img .check-img {
  position: absolute;
  visibility: hidden;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .wrapper-img img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .wrapper-img p {
  font-weight: 400;
  font-size: 14px;
  margin-top: 1rem;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .wrapper-img .skeleton__loading {
  border-radius: 10px;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .foto-masuk,
.detail .main .detail-masuk-keluar .masuk-keluar .card .default-foto {
  display: grid;
  place-items: center;
  width: 315px;
  height: 372px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 100% 0;
     object-position: 100% 0;
  cursor: pointer;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .foto-belum-keluar {
  width: 75px;
  height: 75px;
  cursor: default;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .pop-up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .pop-up .img-user {
  height: 80vh;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .note,
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates {
  padding: 1rem;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .note .location,
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates .location {
  width: 70%;
  margin: 0;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .note h3,
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates h3 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .note p,
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates p {
  font-size: 10px;
  color: #9b9b9b;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates {
  background-color: #f9f9f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates h3 {
  margin-bottom: 0;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates .map-and-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates .wrapper-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates .wrapper-status .valid-masuk-pulang {
  margin-right: 0.5rem;
}
.detail .main .detail-masuk-keluar .masuk-keluar .card .coordinates .wrapper-status .status {
  font-size: 8px;
}
.detail .main .detail__izin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 65%;
  padding: 2rem 2.5rem;
}
.detail .main .detail__izin .wrapper__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 1rem;
}
.detail .main .detail__izin .wrapper__header h1 {
  color: black;
  font-size: 18px;
  font-weight: 500;
}
.detail .main .detail__izin .wrapper__header p {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
}
.detail .main .detail__izin .wrapper__tanggal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}
.detail .main .detail__izin .wrapper__tanggal p {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
}
.detail .main .detail__izin .wrapper__file {
  width: 400px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid #3661eb;
  background: rgba(39, 127, 254, 0.1);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.detail .main .detail__izin .wrapper__file .image {
  height: 80%;
}
.detail .main .detail__izin .wrapper__file p {
  color: #737373;
  font-size: 12px;
  font-weight: 400;
}
.detail .main .detail__izin .wrapper__catatan h1 {
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.detail .main .detail__izin .wrapper__catatan p {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}
.detail .main .detail__izin .bg-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.detail .main .detail__izin .bg-modal img {
  height: 80vh;
}
.detail .main .detail-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 35%;
  padding: 2rem 2rem;
  border-left: 1px solid #ebf0fe;
  height: 90vh;
}
.detail .main .detail-profile .div-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 3rem;
}
.detail .main .detail-profile .div-1 h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2rem;
}
.detail .main .detail-profile .div-1 img {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.detail .main .detail-profile .div-1 h3 {
  font-size: 14px;
  font-weight: 400;
  color: #5a6474;
  margin: 1rem 0 0.25rem;
}
.detail .main .detail-profile .div-1 p {
  font-size: 11px;
  color: #9b9b9b;
}
.detail .main .detail-profile .div-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 2rem;
}
.detail .main .detail-profile .info {
  width: 115px;
}
.detail .main .detail-profile .info h3 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.detail .main .detail-profile .info p {
  font-size: 12px;
  color: #9b9b9b;
}
.detail .main .detail-profile .back-btn {
  width: 125px;
  color: white;
  border: none;
  padding: 0.5rem 0;
  background-color: #3661eb;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  margin-top: 7.25rem;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}
.detail .mape {
  width: 40px;
  height: 40px;
  position: relative;
}
.detail .map-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999;
}
.detail .leaflet-container {
  width: 500px;
  height: 500px;
}
.detail .wrapper-close {
  position: absolute;
  z-index: 999;
  width: 24px;
  height: 24px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transform: translate(250px, -290px);
          transform: translate(250px, -290px);
}
.detail .close {
  position: absolute;
  top: 1rem;
  right: -1rem;
  cursor: pointer;
  z-index: 999;
}

.sidebar-right .kehadiran-terbaru {
  padding: 2rem;
}
.sidebar-right .kehadiran-terbaru .header-refresh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1.5rem;
}
.sidebar-right .kehadiran-terbaru .header-refresh h3 {
  font-size: 20px;
  font-weight: 600;
}
.sidebar-right .kehadiran-terbaru .header-refresh img {
  cursor: pointer;
}
.sidebar-right .kehadiran-terbaru ul .loading::before {
  margin: 0 1rem;
}
.sidebar-right .kehadiran-terbaru ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  margin-bottom: 1rem;
}
.sidebar-right .kehadiran-terbaru ul li div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.sidebar-right .kehadiran-terbaru ul li div p {
  font-size: 12px;
  font-weight: 600;
}
.sidebar-right .kehadiran-terbaru ul li div p:nth-child(2) {
  color: #9b9b9b;
}
.sidebar-right .kehadiran-terbaru ul li img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 1.5rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.sidebar-right .kehadiran-terbaru .skeleton-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  margin-bottom: 1rem;
  gap: 1rem;
}
.sidebar-right .profile {
  border: 1px solid #eceef2;
  border-left: none;
  border-right: none;
  width: 100%;
}