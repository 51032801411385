.dashboard {
  width: 1215px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dashboard .search {
  background-position: 5% 50%;
  background-color: #f3f3f3;
  text-indent: 2rem;
  border: none;
  width: 300px;
  padding: 1rem;
  font-size: 16px;
  outline: none;
  font-weight: 400;
  margin-bottom: 2rem;
}
.dashboard h1 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 1rem;
}
.dashboard .statistic-chart {
  width: 100%;
  background-color: white;
  padding: 2rem 2rem 1rem 3rem;
  border: 1px solid #ebf0fe;
  border-radius: 10px;
  margin-top: 1rem;
}
.dashboard .statistic-chart .div-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem;
}
.dashboard .statistic-chart .div-1 p {
  font-weight: 500;
  font-size: 14px;
}
.dashboard .statistic-chart .div-1 .dropdown {
  cursor: pointer;
}
.dashboard .statistic-chart .div-1 .dropdown button {
  width: 90px;
  height: 25px;
  padding-left: 12px;
  border: none;
  background-color: #f9f9f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #9b9b9b;
  cursor: pointer;
  padding-right: 0.5rem;
  border-radius: 5px;
}
.dashboard .statistic-chart .div-1 .dropdown button img {
  -webkit-transition: ease-in-out 0.3s transform;
  transition: ease-in-out 0.3s transform;
}
.dashboard .statistic-chart .div-1 .dropdown button img.active {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.dashboard .statistic-chart .div-1 .dropdown button:hover {
  background-color: #f3f3f3;
}
.dashboard .statistic-chart .div-1 .dropdown .drop-down-content {
  height: auto;
  position: absolute;
  z-index: 9999;
}
.dashboard .statistic-chart .div-1 .dropdown .drop-down-content ul {
  margin-top: 0.5rem;
  padding-left: 0;
  outline: 1px solid #eceef2;
  border-radius: 5px;
}
.dashboard .statistic-chart .div-1 .dropdown .drop-down-content ul li {
  width: 90px;
  height: 25px;
  padding-left: 12px;
  list-style: none;
  font-size: 13px;
  background-color: white;
  width: 90px;
  color: #9b9b9b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dashboard .statistic-chart .div-1 .dropdown .drop-down-content ul li:hover {
  background-color: #f3f3f3;
}
.dashboard .statistic-chart .div-1 .dropdown .drop-down-content ul .activeDropdownli {
  background-color: #f3f3f3;
}
.dashboard .statistic-chart .div-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dashboard .statistic-chart .div-2 .recharts-wrapper {
  position: relative;
  right: 30px;
}
.dashboard .statistic-chart .div-2 .chartInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dashboard .statistic-chart .div-2 .chartInfo p:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.dashboard .statistic-chart .div-2 .chartInfo .role {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
  color: #9b9b9b;
  font-weight: 500;
  font-size: 12px;
}
.dashboard .statistic-chart .div-2 .chartInfo .role div {
  width: 20px;
  height: 20px;
  background-color: #3661eb;
  margin-right: 1rem;
}
.dashboard .statistic-chart .div-2 .chartInfo .staff div {
  background-color: #c2d2ff;
}
.dashboard .statistic-chart .div-2 .chartInfo .btn-download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  margin-top: auto;
  background-color: #3661eb;
  border: none;
  padding: 0.7rem 1rem;
  width: 190px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}
.dashboard .statistic-chart .div-2 .chartInfo .btn-download img {
  margin-right: 1rem;
  width: 15px;
  height: 15px;
}
.dashboard .statistic-chart .div-2 .empty__container {
  width: 739px;
  height: 300px;
  background-color: #f3f3f3;
  display: grid;
  place-items: center;
  font-size: 12px;
  border-radius: 5px;
}

.sidebar-right {
  border-left: 1px solid #ebf0fe;
  position: relative;
  height: 100vh;
}
.sidebar-right .wrapper-calendar {
  margin-top: 1rem;
}
.sidebar-right .wrapper-calendar,
.sidebar-right .jadwal {
  padding: 0 2rem;
}
.sidebar-right .jadwal {
  margin-top: 2rem;
}
.sidebar-right .jadwal .loading::before {
  margin: 2rem 1rem;
}
.sidebar-right .jadwal h1 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.sidebar-right .jadwal .card-jadwal {
  width: 100%;
  border: 1px solid #ebf0fe;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.sidebar-right .jadwal .card-jadwal h4 {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.sidebar-right .jadwal .card-jadwal p {
  font-size: 12px;
  color: #9b9b9b;
}
.sidebar-right .jadwal .card-jadwal span {
  font-weight: 500;
}
.sidebar-right .jadwal .card-jadwal div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}