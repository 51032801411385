.karyawan {
  width: 1215px;
  padding-left: 2rem;
  padding-bottom: 2rem;
}
.karyawan .loading {
  width: 100% !important;
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
}
.karyawan .loading p {
  font-size: 14px;
}
.karyawan .loading::before {
  margin: 0;
}
.karyawan .search-and-profile {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 2rem;
}
.karyawan .search-and-profile .wrap-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.karyawan .search-and-profile .wrap-search .search {
  width: 350px;
  height: 48px;
  margin-right: 1rem;
  background-color: #ffffff;
  background-size: 21px;
  padding: 0 3rem;
  color: black;
  border-radius: 5px;
}
.karyawan .search-and-profile .wrap-search .search::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.karyawan .search-and-profile .wrap-search .search::-moz-placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.karyawan .search-and-profile .wrap-search .search:-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.karyawan .search-and-profile .wrap-search .search::-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.karyawan .search-and-profile .wrap-search .search::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.karyawan .search-and-profile .wrap-search .btn-search {
  height: 48px;
}
.karyawan .search-and-profile .profile {
  width: 350px;
  border: none;
}
.karyawan .search-and-profile .profile .dropdown-list.active {
  border-top: 1px solid #eceef2;
}
.karyawan .title-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1rem;
}
.karyawan .title-btn h1 {
  font-weight: 500;
  font-size: 24px;
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.karyawan .title-btn a {
  width: 150px;
  height: 45px;
  background-color: #3661eb;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  display: grid;
  place-items: center;
}
.karyawan .table .th-niy {
  width: 120px;
}
.karyawan .table .th-name {
  width: 243px;
}
.karyawan .table .th-jabatan {
  width: 197px;
}
.karyawan .table .th-nohp {
  width: 126px;
}
.karyawan .table .th-email {
  width: 228px;
}
.karyawan .table .action-col {
  padding-right: 3rem;
}
.karyawan .table .action-col div {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.karyawan .table .action-col img {
  cursor: pointer;
}
.karyawan .table .action-col a {
  margin: 0 1rem;
}
.karyawan .table .row-img {
  padding: 0.5rem 0;
}
.karyawan .table .niy-col {
  padding-left: 1.5rem;
  color: #3661eb;
  font-weight: 500;
}
.karyawan .table .wrap-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 2rem;
}
.karyawan .table .wrap-filter img {
  width: 16px;
  height: 16px;
  cursor: pointer;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.karyawan .table .wrap-filter .arrow-down {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.karyawan .table .wrap-filter .arrow-up {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.karyawan .tabbar-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  background-color: white;
  border: 1px solid #eceef2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}
.karyawan .tabbar-filter .filter-angka {
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.karyawan .tabbar-filter .filter-angka p {
  color: #9b9b9b;
  font-size: 14px;
}
.karyawan .tabbar-filter .wrapper-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 76px;
  width: 100%;
  display: grid;
  place-items: center;
}
.karyawan .dropdown-container {
  height: 100px;
}
.karyawan .wrapper__skeleton {
  height: auto;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.karyawan .wrapper__table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: auto;
}

.detail-karyawan {
  width: 80%;
}
.detail-karyawan .navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #ebf0fe;
  padding: 1rem 2.5rem;
}
.detail-karyawan .navigation div:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.detail-karyawan .navigation div:nth-child(1) img {
  cursor: pointer;
}
.detail-karyawan .navigation div:nth-child(1) h1 {
  font-size: 24px;
  font-weight: 500;
  margin-left: 2rem;
}
.detail-karyawan .detail-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.detail-karyawan .form {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 2rem 2.5rem;
}
.detail-karyawan .form label {
  font-size: 18px;
  font-weight: 500;
  color: black;
  margin-bottom: 1rem;
}
.detail-karyawan .form input {
  height: 45px;
  border: 2px solid #eceef2;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  color: #5a6474;
  padding: 0 1rem;
}
.detail-karyawan .form input::-webkit-input-placeholder {
  color: #9b9b9b;
}
.detail-karyawan .form input::-moz-placeholder {
  color: #9b9b9b;
}
.detail-karyawan .form input:-ms-input-placeholder {
  color: #9b9b9b;
}
.detail-karyawan .form input::-ms-input-placeholder {
  color: #9b9b9b;
}
.detail-karyawan .form input::placeholder {
  color: #9b9b9b;
}
.detail-karyawan .form .nama,
.detail-karyawan .form .niy,
.detail-karyawan .form .email,
.detail-karyawan .form .no-hp,
.detail-karyawan .form .jabatan,
.detail-karyawan .form .gender,
.detail-karyawan .form .alamat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.detail-karyawan .form .nama,
.detail-karyawan .form .niy,
.detail-karyawan .form .email,
.detail-karyawan .form .no-hp,
.detail-karyawan .form .wrapper-jabatan {
  margin-bottom: 1rem;
}
.detail-karyawan .form .at-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 1rem;
  position: absolute;
  right: 0;
  -webkit-transform: translate(-50%, 225%);
          transform: translate(-50%, 225%);
  cursor: pointer;
}
.detail-karyawan .form .niy .pwd-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 1rem;
  position: absolute;
  right: 0;
  -webkit-transform: translate(-50%, 425%);
          transform: translate(-50%, 425%);
  cursor: pointer;
}
.detail-karyawan .form .alamat textarea {
  height: 150px;
  border: 2px solid #eceef2;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  color: #5a6474;
  padding: 1rem 1rem 0;
  resize: none;
  font-family: "Poppins";
}
.detail-karyawan .detail-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 2rem 2rem;
  gap: 1.5rem;
}
.detail-karyawan .detail-profile .wrapper-img {
  position: relative;
}
.detail-karyawan .detail-profile .wrapper-img img {
  -o-object-fit: cover;
     object-fit: cover;
}
.detail-karyawan .detail-profile .wrapper-img .edit-img {
  width: 35px;
  height: 35px;
  background-color: #3661eb;
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.detail-karyawan .detail-profile .wrapper-img .edit-img img {
  width: 20px;
  height: 20px;
}
.detail-karyawan .detail-profile .wrapper-img .edit-img input {
  display: none;
}
.detail-karyawan .detail-profile .select .select-heading {
  font-size: 18px;
  font-weight: 500;
  color: black;
  margin-bottom: 1rem;
}
.detail-karyawan .detail-profile .select h3 {
  font-size: 14px;
  font-weight: 400;
  color: #5a6474;
  margin: 1rem 0;
}
.detail-karyawan .detail-profile .div-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 3rem;
}
.detail-karyawan .detail-profile .div-1 h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2rem;
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.detail-karyawan .detail-profile .div-1 .img-profile {
  width: 124px;
  height: 124px;
  border-radius: 50%;
}
.detail-karyawan .detail-profile .div-1 h3 {
  font-size: 14px;
  font-weight: 400;
  color: #5a6474;
  margin: 1rem 0 0.25rem;
}
.detail-karyawan .detail-profile .div-1 p {
  font-size: 11px;
  color: #9b9b9b;
}

.wrapper-container-jabatan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
}
.wrapper-container-jabatan .container-jabatan {
  width: auto;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 8px;
}
.wrapper-container-jabatan .container-jabatan p {
  font-weight: 400;
  font-size: 14px;
  color: #9b9b9b;
  margin-right: 1rem;
}
.wrapper-container-jabatan .btn-plus,
.wrapper-container-jabatan img {
  cursor: pointer;
}

.modal-open {
  overflow: hidden;
}

.bg-modal {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  margin: auto;
  z-index: 999;
}
.bg-modal .modal-container {
  width: 500px;
  height: 550px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2rem 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.bg-modal .modal-container h1 {
  font-weight: 500;
  font-size: 24px;
}
.bg-modal .modal-container .search {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: #f3f3f3;
  padding-left: 1rem;
  margin-top: 2rem;
  border: none;
  background-position: right 1rem center;
  background-size: 16px;
}
.bg-modal .modal-container .wrapper-list {
  width: 100%;
  max-height: 350px;
  margin-top: 1.5rem;
  overflow-y: auto;
}
.bg-modal .modal-container .wrapper-list span {
  display: block;
  text-align: center;
}
.bg-modal .modal-container .wrapper-list .container-list {
  width: 100%;
  height: 36px;
  background: #ebf0fe;
  border-radius: 7.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.bg-modal .modal-container .wrapper-list .container-list p {
  font-weight: 500;
  font-size: 14px;
  color: #3661eb;
}

.validator-text {
  color: #ff4530;
  font-size: 12px;
  margin: 0.25rem 0rem 0rem;
}

.jadwal-absensi {
  margin-top: 2rem;
}
.jadwal-absensi h1 {
  font-size: 18px;
  font-weight: 500;
  color: black;
  margin-bottom: 1rem;
}
.jadwal-absensi .container-jadwal {
  margin-bottom: 0.5rem;
}
.jadwal-absensi .container-jadwal,
.jadwal-absensi .container-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.jadwal-absensi .container-jadwal p,
.jadwal-absensi .container-time p {
  font-weight: 400;
  font-size: 14px;
  color: #5a6474;
}
.jadwal-absensi .container-jadwal .container-time,
.jadwal-absensi .container-time .container-time {
  width: 80%;
}
.jadwal-absensi .container-jadwal input,
.jadwal-absensi .container-time input {
  width: 125px;
  height: 35px;
  border: 1px solid #9b9b9b;
  border-radius: 7.5px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  outline: none;
}
.jadwal-absensi .container-jadwal input:focus,
.jadwal-absensi .container-time input:focus {
  border: 2px solid #3661eb;
}
.jadwal-absensi .container-jadwal input::-webkit-calendar-picker-indicator,
.jadwal-absensi .container-time input::-webkit-calendar-picker-indicator {
  background: none;
  background-image: url(../../assets/icons/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.jadwal-absensi .container-jadwal .line,
.jadwal-absensi .container-time .line {
  width: 8px;
  height: 2px;
  background: #9b9b9b;
  border-radius: 10px;
}