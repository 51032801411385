.kalender {
  width: 1215px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 5rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.kalender button {
  border: none;
}
.kalender .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 2px solid #eceef2;
  border-right: 2px solid #eceef2;
  width: 100%;
}
.kalender .header .left-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}
.kalender .header .left-header .wrapper-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kalender .header .left-header .wrapper-arrow div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.kalender .header .left-header .wrapper-arrow div .arrow-down {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.kalender .header .left-header .wrapper-arrow div:hover {
  background-color: rgba(75, 75, 75, 0.332);
}
.kalender .header .left-header h2 {
  font-size: 30px;
  line-height: 1.75rem;
  font-weight: 400;
}
.kalender .header .left-header button {
  width: 75px;
  height: 45px;
  background-color: white;
  border: 2px solid #eceef2;
  color: black;
  font-weight: 500;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}
.kalender .header .left-header button:hover {
  background-color: #f9f9f9;
}
.kalender .header .right-header button {
  width: 150px;
  height: 45px;
}
.kalender .header .right-header input {
  display: none;
}
.kalender .header .bg-modal .modal-konfirm {
  width: 500px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.kalender .header .bg-modal .modal-konfirm svg {
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 13px;
  height: 13px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.kalender .header .bg-modal .modal-konfirm img {
  width: 128px;
  height: 128px;
}
.kalender .header .bg-modal .modal-konfirm h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #5a6474;
  margin: 2rem 0;
}
.kalender .header .bg-modal .modal-konfirm p {
  -ms-flex-item-align: center;
      align-self: center;
  font-weight: 500;
  font-size: 12px;
  color: #5a6474;
}
.kalender .header .bg-modal .modal-konfirm button {
  border: none;
  outline: none;
  display: grid;
  place-items: center;
  background-color: #3661eb;
  color: #ffffff;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  margin-top: 2rem;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}
.kalender .header .today {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1.25rem;
  border: none;
  border-radius: 0.25rem;
  border-width: 1px;
}
.kalender .header .wrapper-preview {
  display: grid;
  place-items: center;
}
.kalender .header .wrapper-preview .modal {
  width: 70%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.kalender .header .wrapper-preview .modal div:first-child {
  height: 90%;
  width: 100%;
  overflow: auto;
  padding: 1rem;
}
.kalender .header .wrapper-preview .modal div:first-child::-webkit-scrollbar {
  height: unset;
}
.kalender .header .wrapper-preview .modal .container-btn {
  width: 100%;
  margin-top: 1rem;
  border-top: 1px solid #eceef2;
  padding: 0 1rem;
}
.kalender .header .wrapper-preview .modal .container-btn div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  gap: 1rem;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  overflow: hidden;
}
.kalender .header .wrapper-preview .modal .container-btn button {
  width: 150px;
  height: 45px;
}
.kalender .header .wrapper-preview .modal .container-btn .button-cancel {
  background-color: #ff4530;
}
.kalender .sidebar-month {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
}
.kalender .sidebar-month .container-days {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  width: 100%;
  height: 40px;
}
.kalender .sidebar-month .container-days .day {
  display: grid;
  place-items: center;
  border-bottom: 1px solid #eceef2;
  border-right: 1px solid #eceef2;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #9b9b9b;
  font-weight: 500;
  font-size: 14px;
}
.kalender .month {
  display: grid;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
  max-height: 1000px;
}
.kalender .month .day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-right: #e5e7eb 1px solid;
  border-bottom: #e5e7eb 1px solid;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  height: 148px;
  width: 100%;
  position: relative;
}
.kalender .month .day .handle-add {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: rgb(255, 255, 255);
  overflow-y: scroll;
}
.kalender .month .day header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.kalender .month .day header .dd {
  text-align: left;
  font-size: 10px;
  font-weight: 500;
}
.kalender .month .day header h1 {
  margin-right: 2.5rem;
  color: #6b7280;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.kalender .month .day header .currentDay {
  display: grid;
  place-items: center;
  background-color: #2563eb;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ffffff;
}
.kalender .month .day header p {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
}
.kalender .month .day .day-events {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
  cursor: default;
}
.kalender .month .day .day-events p {
  font-weight: 500;
  font-size: 12px;
}
.kalender .month .day .day-events span {
  font-weight: 500;
  font-size: 10px;
  color: #9b9b9b;
}
.kalender .hover-detail-event {
  position: absolute;
  width: 400px;
  max-height: 250px;
  border: 2px solid #ebf0fe;
  border-radius: 10px;
  z-index: 999;
  background-color: white;
  padding: 1rem 1.5rem;
  top: 20px;
  left: 150px;
  cursor: default;
  overflow-y: scroll;
}
.kalender .hover-detail-event .wrapper-judul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.kalender .hover-detail-event .wrapper-judul h1 {
  font-weight: 500;
  font-size: 14px;
  color: #21d2ff;
}
.kalender .hover-detail-event .wrapper-judul h2 {
  font-weight: 500;
  font-size: 10px;
  color: #3661eb;
  cursor: pointer;
}
.kalender .hover-detail-event .tanggal,
.kalender .hover-detail-event .lokasi,
.kalender .hover-detail-event .deskripsi {
  font-weight: 500;
  font-size: 10px;
  color: #9b9b9b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kalender .hover-detail-event .tanggal p,
.kalender .hover-detail-event .lokasi p,
.kalender .hover-detail-event .deskripsi p {
  font-weight: 500;
  font-size: 10px;
  color: #000000;
  margin-left: 0.5rem;
}
.kalender .hover-detail-event .deskripsi {
  margin-top: 0.5rem;
}
.kalender .small-calendar header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.kalender .small-calendar .grid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.kalender .small-calendar .grid .hari {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
.kalender .small-calendar .grid .btn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 100%;
}
.kalender .small-calendar .grid .btn span {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.kalender .small-calendar .grid .btn-today {
  background-color: #3b82f6;
  color: #ffffff;
  border-radius: 9999px;
}
.kalender .modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100vh;
}
.kalender .modal form {
  background-color: #ffffff;
  width: 500px;
  height: 470px;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.kalender .modal form .wrapper-input {
  padding: 2rem 1.5rem 1rem;
}
.kalender .modal form .wrapper-input .kegiatan-input {
  width: 400px;
  border: none;
  border-bottom: 2px solid #3661eb;
  outline: none;
  font-size: 24px;
  margin-left: 2.5rem;
  margin-bottom: 1.5rem;
}
.kalender .modal form .wrapper-input .kegiatan-input::-webkit-input-placeholder {
  color: #9b9b9b;
}
.kalender .modal form .wrapper-input .kegiatan-input::-moz-placeholder {
  color: #9b9b9b;
}
.kalender .modal form .wrapper-input .kegiatan-input:-ms-input-placeholder {
  color: #9b9b9b;
}
.kalender .modal form .wrapper-input .kegiatan-input::-ms-input-placeholder {
  color: #9b9b9b;
}
.kalender .modal form .wrapper-input .kegiatan-input::placeholder {
  color: #9b9b9b;
}
.kalender .modal form .wrapper-input .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.kalender .modal form .wrapper-input .date p {
  color: #5a6474;
}
.kalender .modal form .wrapper-input .description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 1rem;
}
.kalender .modal form .wrapper-input .description textarea {
  width: 400px;
  height: 100px;
  resize: none;
  outline: none;
  border: none;
  background-color: #f3f3f3;
  padding: 10px;
  font-family: "poppins";
  border-radius: 5px;
}
.kalender .modal form .wrapper-input .description img {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}
.kalender .modal form .wrapper-input .checkbox-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 2rem;
  margin: 1rem 0 1rem;
  gap: 1rem;
}
.kalender .modal form .wrapper-input .checkbox-form .wrap-for,
.kalender .modal form .wrapper-input .checkbox-form .wrap-is-libur {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}
.kalender .modal form .wrapper-input .checkbox-form .wrap-label-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kalender .modal form .wrapper-input .checkbox-form label {
  font-size: 12px;
  color: #5a6474;
  margin-left: 0.5rem;
}
.kalender .modal form header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  background-color: #3661eb;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px 10px 0px 0px;
}
.kalender .modal form header .action {
  padding-right: 1rem;
}
.kalender .modal form header .action .trash {
  margin-right: 1rem;
}
.kalender .modal form header h3 {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.kalender .modal form .wrapper-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 2rem;
  margin-top: 2rem;
  gap: 0.5rem;
}
.kalender .modal form .wrapper-label .label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}
.kalender .modal form .wrapper-label .label img {
  width: 10px;
  height: 10px;
}
.kalender .modal form footer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 1.5rem;
}
.kalender .modal form footer .submit {
  width: 115px;
  height: 40px;
  background: #3661eb;
  border-radius: 5px;
  color: white;
}

.wrapper-kalender-add {
  height: 100vh;
  width: 1179px;
  background-color: #f9f9f9;
  border-right: 1px solid #ebf0fe;
}
.wrapper-kalender-add .nav-kalender {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #ebf0fe;
  padding: 1rem 2.5rem;
  width: 100%;
  background-color: #ffffff;
}
.wrapper-kalender-add .nav-kalender .wrapper-back-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
}
.wrapper-kalender-add .nav-kalender .wrapper-back-btn img {
  cursor: pointer;
}
.wrapper-kalender-add .nav-kalender .wrapper-back-btn h1 {
  font-size: 24px;
  font-weight: 500;
  margin-left: 2rem;
}
.wrapper-kalender-add .nav-kalender .wrapper-action-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}
.wrapper-kalender-add .nav-kalender .wrapper-action-btn button {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: #ff4530;
}
.wrapper-kalender-add .nav-kalender .wrapper-action-btn .btn-submit:disabled {
  background-color: #5a6474;
  opacity: 0.7;
  cursor: not-allowed;
}
.wrapper-kalender-add .wrapper-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 2rem 2.5rem;
  width: 1179px;
  gap: 3rem;
}
.wrapper-kalender-add .wrapper-content h3 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1rem;
}
.wrapper-kalender-add .wrapper-content .input {
  border: 1px solid #9b9b9b;
  border-radius: 7.5px;
  padding-left: 1rem;
  padding-right: 0.25rem;
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 12px;
  height: 35px;
  outline: none;
}
.wrapper-kalender-add .wrapper-content .wrapper-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 60%;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input::-webkit-input-placeholder {
  color: #9b9b9b;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input::-moz-placeholder {
  color: #9b9b9b;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input:-ms-input-placeholder {
  color: #9b9b9b;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input::-ms-input-placeholder {
  color: #9b9b9b;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input::placeholder {
  color: #9b9b9b;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input:focus {
  border: 2px solid #3661eb;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input-nama-event {
  height: 60px;
  font-size: 20px;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .checkbox-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .checkbox-form .wrap-label-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .checkbox-form .wrap-label-radio input {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .checkbox-form .wrap-label-radio label {
  font-size: 12px;
  cursor: pointer;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time {
  margin: 1rem 0;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 0.5rem;
  cursor: pointer;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .react-calendar {
  z-index: 1;
  position: absolute;
  padding: 1rem;
  max-width: inherit;
  top: 40px;
  left: 0;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .second-calendar {
  -webkit-transform: translate(108%, 56%);
          transform: translate(108%, 56%);
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .waktu {
  width: 50%;
  border: 1px solid #9b9b9b;
  border-radius: 7.5px;
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 12px;
  height: 35px;
  outline: none;
  position: relative;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .waktu .wrap__text {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1rem;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .waktu .arrow__down {
  background-color: #9b9b9b;
  -webkit-mask: url(../../assets/icons/arrow-down.svg) no-repeat center;
  width: 10px;
  height: 10px;
  -webkit-mask: url(../../assets/icons/arrow-down.svg) no-repeat center;
          mask: url(../../assets/icons/arrow-down.svg) no-repeat center;
  -webkit-transition: ease-in-out 0.3s transform;
  transition: ease-in-out 0.3s transform;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .waktu .arrow__down.active {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .waktu.active {
  border: 2px solid #3661eb;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .waktu__selesai.active {
  border: 2px solid #3661eb;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input[type=text] {
  width: 50%;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input::-webkit-input-placeholder {
  color: black;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input::-moz-placeholder {
  color: black;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input:-ms-input-placeholder {
  color: black;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input::-ms-input-placeholder {
  color: black;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input::placeholder {
  color: black;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input[type=time] {
  width: 22.5%;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
  background-image: url(../../assets/icons/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .line {
  width: 8px;
  height: 1px;
  background-color: black;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .wrapper-error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 2rem;
  width: 49%;
  margin-left: auto;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .wrapper-time .wrapper-error p {
  width: 50%;
  text-align: center;
}
.wrapper-kalender-add .wrapper-content .wrapper-form .input-deskripsi-event {
  height: 140px;
  padding-top: 0.5rem;
  resize: none;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi {
  width: 40%;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown {
  position: relative;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown input {
  width: 100%;
  outline: #f9f9f9;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container {
  background-color: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 7.5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  overflow-y: scroll;
  max-height: 200px;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-outer-item {
  width: 100%;
  border-bottom: 1px solid #eceef2;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-item.not-found div {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 40px;
  width: 390px;
  margin: 0 auto;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-item div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-item img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-item p {
  font-size: 12px;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-item span {
  font-size: 10px;
  color: #9b9b9b;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-search-dropdown .dropdown-container .dropdown-item button {
  outline: none;
  border: none;
  width: 75px;
  height: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-checkbox {
  width: 100%;
  gap: 0.5rem 0.25rem;
  margin: 0.5rem 0 1.5rem;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-checkbox .wrapper-checkbox-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-checkbox .wrapper-checkbox-label label {
  font-size: 12px;
  color: #5a6474;
  cursor: pointer;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-checkbox .wrap_skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: 0.5rem;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-checkbox .wrap_skeleton .wrap_label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  margin-right: 1rem;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-top-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-top-list div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-top-list div h4 {
  font-weight: 400;
  font-size: 14px;
  margin-right: 1rem;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-top-list div .count {
  width: 20px;
  height: 20px;
  background-color: rgba(39, 127, 254, 0.1);
  font-weight: 500;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #277ffe;
  text-align: center;
  border-radius: 5px;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-top-list p {
  font-weight: 400;
  font-size: 10px;
  color: #3661eb;
  cursor: pointer;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  overflow-y: auto;
  height: 400px;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list .list-content-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list .list-content-left img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 1rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list .list-content-left div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list .list-content-left div p {
  font-weight: 500;
  font-size: 14px;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list .list-content-left div span {
  font-weight: 500;
  font-size: 10px;
  color: #9b9b9b;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list input {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  background-color: var(--form-background);
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 2px solid #3661eb;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list input::before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/check-with-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  background-color: white;
  cursor: pointer;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .wrapper-list input:checked::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .container_skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  overflow-y: auto;
  height: 500px;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .container_skeleton .container__list__skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column .container_skeleton .container__list__skeleton .left__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}
.wrapper-kalender-add .wrapper-content .wrapper-partisipasi .wrapper-column.overflowed {
  padding-right: 1rem;
}
.wrapper-kalender-add .bg-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.wrapper-kalender-add .bg-modal .alert-modal {
  width: 350px;
  height: 225px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.wrapper-kalender-add .bg-modal .alert-modal h1 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 1rem;
  margin-right: auto;
}
.wrapper-kalender-add .bg-modal .alert-modal p {
  font-weight: 500;
  font-size: 14px;
  margin-right: auto;
}
.wrapper-kalender-add .bg-modal .alert-modal div {
  margin-top: auto;
  background-color: #f9f9f9;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 1rem;
  padding: 0 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wrapper-kalender-add .bg-modal .alert-modal button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.wrapper-kalender-add .bg-modal .alert-modal button:nth-child(1) {
  background-color: #ff4530;
}
.wrapper-kalender-add .bg-modal .alert-modal button:nth-child(2) {
  background-color: #3661eb;
}