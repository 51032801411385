::-webkit-scrollbar {
  width: 14px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}

.wrapper-dashboard,
.wrapper-kehadiran,
.wrapper-karyawan,
.wrapper-kalender,
.not-found,
.no-internet,
.wrapper-detail {
  max-width: 1440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
}

.no-scroll {
  overflow: hidden;
}

.main-dashboard,
.main-kehadiran {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #f9f9f9;
  padding: 2rem;
  width: 70%;
}
.main-dashboard .loading::before,
.main-kehadiran .loading::before {
  margin: 0 0 0.75rem 2.5rem;
}

.button-blue {
  background-color: #3661eb;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.btn-search {
  width: 50px;
  height: 35px;
  border-radius: 3px;
  border: 2px solid #eceef2;
  background-color: white;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.btn-search img {
  width: 13px;
  height: 13px;
}

.search {
  background-image: url(../../assets/icons/search-icon.svg);
  background-repeat: no-repeat;
  background-position: 5% 50%;
  background-size: 13px;
  border: 2px solid #ebf0fe;
  width: 60%;
  padding: 0.5rem 2.5rem;
  outline: none;
  font-weight: 400;
  color: black;
}

.search::-webkit-input-placeholder {
  color: #a6a6a6;
}

.search::-moz-placeholder {
  color: #a6a6a6;
}

.search:-ms-input-placeholder {
  color: #a6a6a6;
}

.search::-ms-input-placeholder {
  color: #a6a6a6;
}

.search::placeholder {
  color: #a6a6a6;
}

.search:focus {
  border: 2px solid #3661eb;
}

.info__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 400px;
  height: 50px;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-transform: translate(10%, -50%);
          transform: translate(10%, -50%);
  z-index: 9999;
  color: white;
  background-color: #04d57d;
}
.info__box img:nth-child(1) {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}
.info__box img:nth-child(3) {
  width: 12px;
  height: 12px;
  margin-left: auto;
  cursor: pointer;
}
.info__box h3 {
  font-size: 14px;
  font-weight: 500;
}

.wrapper-circular {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  gap: 1rem;
}
.wrapper-circular .card-circular-statistic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 250px;
  background-color: white;
  padding: 1rem;
  border: 1px solid #ebf0fe;
  border-radius: 10px;
}
.wrapper-circular .card-circular-statistic .wrapper-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 51px;
}
.wrapper-circular .card-circular-statistic .wrapper-value p {
  margin: 0;
  margin-left: 1.5rem;
}
.wrapper-circular .card-circular-statistic .wrapper-value .p1 {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
}
.wrapper-circular .card-circular-statistic .wrapper-value .p2 {
  color: #3661eb;
  font-size: 20px;
  font-weight: 600;
}

.sidebar-right {
  border-left: 1px solid #ebf0fe;
  border-right: 1px solid #ebf0fe;
  width: 30%;
  overflow: auto;
}

.sidebar-right::-webkit-scrollbar {
  width: 0;
  display: none;
}

.sidebar-right::-webkit-scrollbar-thumb {
  display: none;
}

.profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 350px;
  height: 100px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #eceef2;
}
.profile .line {
  border-bottom: 1px solid #eceef2;
  width: 100%;
  margin-top: 2rem;
}
.profile .wrapper-profile-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  position: relative;
  z-index: 100;
  background-color: white;
}
.profile .profile-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 263px;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}
.profile .profile-detail img:nth-child(1) {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
}
.profile .profile-detail p {
  font-weight: 500;
  font-size: 14px;
}
.profile .profile-detail img:nth-child(3) {
  margin-left: auto;
  width: 15px;
  height: 29px;
  -webkit-transition: -webkit-transform ease-in-out 300ms;
  transition: -webkit-transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms, -webkit-transform ease-in-out 300ms;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.profile .profile-detail img:nth-child(3).active {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.profile .dropdown-container {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
}
.profile .dropdown-container .dropdown-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-right: 1px solid #eceef2;
  border-left: 1px solid #eceef2;
  height: 75px;
  width: 100%;
  z-index: -1;
  background-color: white;
  padding: 0 2rem;
  text-decoration: none;
}
.profile .dropdown-container .dropdown-list p {
  font-weight: 500;
  font-size: 14px;
  color: #9b9b9b;
}
.profile .dropdown-container .dropdown-list div {
  width: 18px;
}
.profile .dropdown-container .dropdown-list:nth-child(1) {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: -webkit-transform 300ms ease-in-out;
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}
.profile .dropdown-container .dropdown-list.active:nth-child(1),
.profile .dropdown-container .dropdown-list.active:nth-child(2) {
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  border-bottom: 1px solid #eceef2;
}
.profile .dropdown-container .dropdown-list:nth-child(2) {
  -webkit-transform: translateY(-75px);
          transform: translateY(-75px);
  -webkit-transition: -webkit-transform 300ms ease-in-out;
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}
.profile .dropdown-container.active {
  z-index: 99;
}

.react-calendar {
  width: 300px;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
  position: relative;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 11px;
  font-weight: 500;
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  font-size: 12px;
  height: 42px;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #3661eb;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3661eb;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.react-calendar__navigation__arrow {
  font-size: 30px;
  color: #3661eb;
}

.react-calendar__tile--hasActive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__next-button {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.react-calendar__navigation__prev-button {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none;
  visibility: hidden;
}

.tabbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.tabbar a {
  text-decoration: none;
}
.tabbar .tabbar-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tabbar .tabbar-text div {
  min-width: 130px;
  height: 60px;
  text-align: center;
  -webkit-transition: border 0.3s ease-in-out;
  transition: border 0.3s ease-in-out;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  border-bottom: none;
  color: #9b9b9b;
  display: grid;
  place-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0 2rem;
  white-space: nowrap;
}
.tabbar .tabbar-text div:hover {
  color: #3661eb;
}
.tabbar .tabbar-text div.active {
  color: #3661eb;
}
.tabbar .line {
  width: 130px;
  height: 1px;
  background-color: #3661eb;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.filter {
  cursor: pointer;
  -webkit-transform: translateY(-0.25rem);
          transform: translateY(-0.25rem);
  position: relative;
  z-index: 98;
  width: 24px;
  height: 24px;
}
.filter ul {
  position: absolute;
  -webkit-transform: translateX(-27%);
          transform: translateX(-27%);
  margin-top: 0.5rem;
  width: 120px;
  height: 60px;
}
.filter ul li {
  list-style: none;
  list-style: none;
  font-size: 13px;
  background-color: white;
  width: 120px;
  height: 30px;
  z-index: 999;
  cursor: pointer;
  font-size: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 1rem;
}
.filter ul li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.filter ul li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.filter ul li:hover {
  background-color: #f3f3f3;
}
.filter ul .activeDropdownli {
  background-color: #f3f3f3;
}

.table {
  width: 100%;
  background-color: white;
  border: 1px solid #eceef2;
  border-top: none;
  position: relative;
  border-spacing: 0px;
}
.table thead th {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding: 1.5rem 0;
}
.table thead th:nth-child(1) {
  padding-left: 1.5rem;
}
.table tbody tr {
  width: 100%;
}
.table tbody tr:nth-child(odd) {
  background-color: #ebf0fe;
}
.table tbody .btn-detail {
  background-color: #3661eb;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  font-size: 10px;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
}
.table td {
  font-size: 10px;
  color: #9b9b9b;
}
.table .niy-col {
  padding-left: 1.5rem;
  color: #3661eb;
  font-weight: 500;
  font-size: 10px;
}
.table .row-img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}
.table .row-img img {
  width: 35px;
  height: 35px;
  margin-right: 1rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.table .loading-table {
  position: absolute;
  width: 100% !important;
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
}

.map {
  width: 100%;
  height: 375px;
}

.leaflet-container {
  width: 100%;
  height: 375px;
}

.pagination-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style-type: none;
  margin-top: 2.5rem;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 0.01071em;
  line-height: 1.43;
  font-size: 14px;
  min-width: 32px;
  font-weight: 600;
  border-radius: 4px;
  border: 2px solid #eceef2;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  border: solid 2px #3661eb;
  color: #3661eb;
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  -webkit-transform: rotate(-135deg) translate(-50%);
          transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.btn-submit {
  width: 125px;
  height: 40px;
  background-color: #3661eb;
  border-radius: 8px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
}

.btn-submit:disabled {
  background-color: #5a6474;
  opacity: 0.7;
  cursor: not-allowed;
}

.wrapper-not-found {
  width: 82%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}
.wrapper-not-found h1 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  margin-top: 1rem;
}
.wrapper-not-found p {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.wrapper-not-found img {
  width: 110px;
  height: 110px;
}
.wrapper-not-found button {
  width: 120px;
  height: 32px;
  margin-top: 1rem;
}

.loading:before {
  display: block;
  height: 0.1px;
  width: 0.1px;
  content: "";
  -webkit-animation: spin 1s infinite;
  animation: spin 1s infinite;
  border-radius: 100%;
  -webkit-box-shadow: 10px 0 0 3px #3661eb, -10px 0 0 3px #3661eb;
  box-shadow: 10px 0 0 3px #3661eb, -10px 0 0 3px #3661eb;
}

.loading-fullscreen {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: grid;
  place-items: center;
  z-index: 9999;
}
.loading-fullscreen .loading:before {
  height: 10px;
  width: 10px;
  -webkit-box-shadow: 10px 0 0 3px #3661eb, -10px 0 0 3px #3661eb;
  box-shadow: 30px 0 0 2.5px #3661eb, -30px 0 0 2.5px #3661eb;
  border-radius: 0%;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes skeleton-loading {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
@keyframes skeleton-loading {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
.skeleton__loading {
  width: 100%;
  background: #f5f5f5;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(25%, #f5f5f5), color-stop(37%, #e8e8e8), color-stop(63%, #f5f5f5));
  background-image: linear-gradient(90deg, #f5f5f5 25%, #e8e8e8 37%, #f5f5f5 63%);
  background-size: 200% 100%;
  -webkit-animation: skeleton-loading 1.5s infinite linear;
          animation: skeleton-loading 1.5s infinite linear;
}

.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  background-color: var(--form-background);
  margin: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #5a6474;
  border-radius: 3px;
  position: relative;
  display: grid;
  place-content: center;
}

.custom-checkbox::after,
.custom-checkbox::before {
  cursor: pointer;
}

.custom-checkbox::after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: 120ms all ease-in-out;
  transition: 120ms all ease-in-out;
  border-radius: 3px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  z-index: 2;
}

.custom-checkbox:checked::after {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #3661eb;
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
}

.custom-checkbox::before {
  content: "";
  width: 5px;
  height: 5px;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  background-color: white;
  position: relative;
  z-index: 3;
}

.custom-checkbox:checked::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.custom-checkbox:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.popUp {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.popUp .modal__popup {
  width: 350px;
  height: 225px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 250px;
}
.popUp .modal__popup h1 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 1rem;
  margin-right: auto;
}
.popUp .modal__popup p {
  font-weight: 500;
  font-size: 14px;
  margin-right: auto;
}
.popUp .modal__popup div {
  margin-top: auto;
  background-color: #f9f9f9;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 1rem;
  padding: 0 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.popUp .modal__popup button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.popUp .modal__popup button:nth-child(1) {
  background-color: #ff4530;
}
.popUp .modal__popup button:nth-child(2) {
  background-color: #3661eb;
}
.popUp .modal__popup h1 {
  margin: 0;
  margin-bottom: 0.5rem;
}
.popUp .modal__popup p {
  margin: 0;
}
.popUp .modal__popup img {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}
.popUp .modal__popup button {
  margin-top: auto;
  background-color: #3661eb;
  width: 200px;
}

.wrapper__skeleton,
.wrapper__table {
  width: 100%;
  height: 725px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #eceef2;
  border-radius: 10px;
  background-color: white;
}
.wrapper__skeleton .wrapper__tabbar,
.wrapper__table .wrapper__tabbar {
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #eceef2;
  padding-right: 2rem;
}
.wrapper__skeleton .list__text__skeleton,
.wrapper__table .list__text__skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  gap: 5rem;
  padding-left: 2rem;
}
.wrapper__skeleton .row__table,
.wrapper__table .row__table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-left: 2rem;
  height: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.wrapper__skeleton .column__table,
.wrapper__table .column__table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  gap: 2rem;
  padding-left: 2rem;
  height: 68px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ExcelTable2007 {
  border: 1px solid #b0cbef;
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 10px;
}

.ExcelTable2007 TH {
  background-repeat: repeat-x;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #9eb6ce;
  border-width: 0px 1px 1px 0px;
  height: 17px;
  line-height: 17px;
}

.ExcelTable2007 TD {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #d0d7e5;
  border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable2007 TD.heading {
  background-color: #e4ecf7;
  text-align: center;
  border: 1px solid #9eb6ce;
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

.ExcelTable2007 TH.heading {
  background-repeat: none;
}

TH {
  text-align: center;
}