.bg-pengaturan {
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
}

.pengaturan {
  width: 1440px;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3rem;
}
.pengaturan .loading {
  width: 100% !important;
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2rem;
}
.pengaturan .loading p {
  font-size: 14px;
}
.pengaturan .import-user {
  width: 1100px;
  margin-left: auto;
  margin-bottom: 5rem;
}
.pengaturan .import-user .top-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pengaturan .import-user .top-table p {
  font-weight: 500;
  font-size: 14px;
  color: #5a6474;
}
.pengaturan .import-user .top-table input {
  display: none;
}
.pengaturan .import-user .top-table .modal-konfirm {
  width: 500px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.pengaturan .import-user .top-table svg {
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 13px;
  height: 13px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.pengaturan .import-user .top-table img {
  width: 128px;
  height: 128px;
}
.pengaturan .import-user .top-table h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #5a6474;
  margin: 2rem 0;
}
.pengaturan .import-user .top-table p {
  -ms-flex-item-align: center;
      align-self: center;
  font-weight: 500;
  font-size: 12px;
  color: #5a6474;
}
.pengaturan .import-user .top-table button {
  border: none;
  outline: none;
  display: grid;
  place-items: center;
  background-color: #3661eb;
  color: #ffffff;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  margin-top: 2rem;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}
.pengaturan .import-user .top-table .wrapper-preview {
  display: grid;
  place-items: center;
}
.pengaturan .import-user .top-table .wrapper-preview .modal {
  width: 70%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.pengaturan .import-user .top-table .wrapper-preview .modal div:first-child {
  height: 90%;
  width: 100%;
  overflow: auto;
  padding: 1rem;
}
.pengaturan .import-user .top-table .wrapper-preview .modal div:first-child::-webkit-scrollbar {
  height: unset;
}
.pengaturan .import-user .top-table .wrapper-preview .modal .container-btn {
  width: 100%;
  margin-top: 1rem;
  border-top: 1px solid #eceef2;
  padding: 0 1rem;
}
.pengaturan .import-user .top-table .wrapper-preview .modal .container-btn div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  gap: 1rem;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  overflow: hidden;
}
.pengaturan .import-user .top-table .wrapper-preview .modal .container-btn button {
  width: 150px;
  height: 45px;
}
.pengaturan .import-user .top-table .wrapper-preview .modal .container-btn .button-cancel {
  background-color: #ff4530;
}
.pengaturan .import-user .wrapper-tabbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  background-color: white;
  border: 1px solid #ebf0fe;
  border-top: none;
  margin-top: 2rem;
  border-radius: 10px 10px 0px 0px;
}
.pengaturan .import-user .pagination-container {
  padding-bottom: 2rem;
}
.pengaturan .import-user .wrapper-loading {
  background-color: white;
  margin-top: 2rem;
}
.pengaturan .import-user .table .row-img {
  padding: 0.5rem 0;
}
.pengaturan .import-user .wrapper__skeleton {
  margin-top: 2rem;
  height: auto;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.pengaturan .import-user .wrapper__table {
  height: auto;
  padding-bottom: 1rem;
}
.pengaturan .radius-absen {
  width: 100%;
}
.pengaturan .radius-absen h1 {
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 2rem;
}
.pengaturan .radius-absen .container-radius {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}
.pengaturan .radius-absen .container-radius .radius-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.pengaturan .radius-absen .container-radius .radius-info label {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.pengaturan .radius-absen .container-radius .radius-info input {
  width: 187px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 8px;
  outline: none;
  padding: 1rem;
  font-weight: 400;
  font-size: 14px;
  color: #5a6474;
}
.pengaturan .radius-absen .container-radius .radius-info input:focus {
  outline: #3661eb solid 1px;
}
.pengaturan .radius-absen .container-radius .wrapper-btn {
  margin-left: auto;
  -ms-flex-item-align: end;
      align-self: end;
}
.pengaturan .radius-absen .container-radius .wrapper-btn button {
  width: 100px;
  height: 35px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-left: 1rem;
  justify-self: flex-end;
  border: none;
  outline: none;
  background-color: #ffd233;
  color: black;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
}
.pengaturan .radius-absen .container-radius .wrapper-btn .btn-cancel {
  background-color: #ff4530;
  color: white;
}
.pengaturan .radius-absen .container-radius .wrapper-btn button.active {
  background-color: #3661eb;
  color: white;
}
.pengaturan .kategori-karyawan {
  width: 100%;
  margin-bottom: 5rem;
}
.pengaturan .kategori-karyawan a {
  text-decoration: none;
  color: black;
}
.pengaturan .kategori-karyawan h1 {
  font-weight: 500;
  font-size: 32px;
  color: #000000;
  margin-bottom: 2rem;
}
.pengaturan .kategori-karyawan .tambah-kategori {
  width: 100%;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  background-color: #ececec;
  font-weight: 500;
  font-size: 16px;
  color: #9b9b9b;
  cursor: pointer;
}
.pengaturan .kategori-karyawan .container-kategori {
  width: 100%;
  height: 48px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 2rem;
  background-color: #ffffff;
  margin-bottom: 0.5rem;
  position: relative;
}
.pengaturan .kategori-karyawan .container-kategori div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}
.pengaturan .kategori-karyawan .container-kategori img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.pengaturan .kategori-karyawan .container-kategori .dropdown-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(-25px, 50px);
          transform: translate(-25px, 50px);
  background-color: #ffffff;
  gap: 0.5rem;
  border: 1px solid #9b9b9b;
  z-index: 2;
  border-radius: 5px;
}
.pengaturan .kategori-karyawan .container-kategori .dropdown-content div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  cursor: pointer;
  padding: 0.25rem 1rem;
}
.pengaturan .kategori-karyawan .container-kategori .dropdown-content div img {
  width: 12px;
  height: 12px;
}
.pengaturan .kategori-karyawan .container-kategori .dropdown-content div p {
  font-size: 14px;
}
.pengaturan .kategori-karyawan .container-kategori .dropdown-content div:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.pengaturan .kategori-karyawan .modal-kategori {
  width: 675px;
  height: 500px;
  background-color: #ffffff;
  border-radius: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2rem 2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.pengaturan .kategori-karyawan .modal-kategori svg {
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 13px;
  height: 13px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.pengaturan .kategori-karyawan .modal-kategori img {
  width: 128px;
  height: 128px;
}
.pengaturan .kategori-karyawan .modal-kategori h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #5a6474;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.pengaturan .kategori-karyawan .modal-kategori p {
  -ms-flex-item-align: start;
      align-self: start;
  font-weight: 500;
  font-size: 12px;
  color: #5a6474;
  margin-bottom: 1rem;
}
.pengaturan .kategori-karyawan .modal-kategori input {
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #9b9b9b;
  outline: none;
  padding-bottom: 1rem;
}
.pengaturan .kategori-karyawan .modal-kategori input::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.333333px;
  color: #9b9b9b;
}
.pengaturan .kategori-karyawan .modal-kategori input::-moz-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.333333px;
  color: #9b9b9b;
}
.pengaturan .kategori-karyawan .modal-kategori input:-ms-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.333333px;
  color: #9b9b9b;
}
.pengaturan .kategori-karyawan .modal-kategori input::-ms-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.333333px;
  color: #9b9b9b;
}
.pengaturan .kategori-karyawan .modal-kategori input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.333333px;
  color: #9b9b9b;
}
.pengaturan .kategori-karyawan .modal-kategori input:focus {
  border-bottom: 2px solid #3661eb;
}
.pengaturan .kategori-karyawan .modal-kategori button {
  border: none;
  outline: none;
  display: grid;
  place-items: center;
  background-color: #277ffe;
  color: #ffffff;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  margin-top: 2rem;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}
.pengaturan .detail-kategori-karyawan {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.pengaturan .detail-kategori-karyawan .tambah-kategori {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 48px;
  background-color: #ececec;
  margin: 2rem 0 1rem;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #9b9b9b;
}
.pengaturan .detail-kategori-karyawan .tambah-kategori img {
  margin-left: 1rem;
}
.pengaturan .detail-kategori-karyawan a {
  text-decoration: none;
}
.pengaturan .detail-kategori-karyawan .wrapper-kategori {
  width: 40%;
}
.pengaturan .detail-kategori-karyawan .wrapper-kategori h1 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 2rem;
}
.pengaturan .detail-kategori-karyawan .wrapper-kategori .container-list-kategori {
  max-height: 510px;
  width: 370px;
  overflow-y: auto;
}
.pengaturan .detail-kategori-karyawan .wrapper-kategori .kategori {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  margin-bottom: 0.5rem;
  width: 350px;
  height: 48px;
  padding: 0 1.5rem;
  border-radius: 5px;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  cursor: pointer;
}
.pengaturan .detail-kategori-karyawan .wrapper-kategori .kategori p {
  font-weight: 500;
  font-size: 12px;
  color: #5a6474;
}
.pengaturan .detail-kategori-karyawan .wrapper-kategori .kategori span {
  font-weight: 500;
  font-size: 14px;
  color: #5a6474;
}
.pengaturan .detail-kategori-karyawan .wrapper-kategori .kategori.active {
  background-color: #f3f3f3;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori {
  width: 60%;
  margin-bottom: 5rem;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 2rem;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-text h1 {
  font-weight: 500;
  font-size: 20px;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-text h2 {
  font-weight: 500;
  font-size: 14px;
  color: #3661eb;
  cursor: pointer;
  position: relative;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  overflow-y: scroll;
  height: 500px;
  padding-right: 0.5rem;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list .list-content-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list .list-content-left img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 1rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list .list-content-left div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list .list-content-left div p {
  font-weight: 500;
  font-size: 14px;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list .list-content-left div span {
  font-weight: 500;
  font-size: 10px;
  color: #9b9b9b;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list input {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  background-color: var(--form-background);
  width: 24px;
  height: 24px;
  border: 2px solid #3661eb;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-content: center;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list input::before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/check-with-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  background-color: white;
  cursor: pointer;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .wrapper-list input:checked::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .empty {
  text-align: center;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .container__list__skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .wrapper-column .container__list__skeleton .left__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .total-selected-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2rem;
}
.pengaturan .detail-kategori-karyawan .wrapper-edit-kategori .total-selected-user button {
  background-color: #ff4530;
  height: 45px;
  width: 125px;
}
.pengaturan .detail-kategori-karyawan .bg-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota {
  width: 500px;
  height: 550px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2rem 0rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota h1 {
  font-weight: 500;
  font-size: 24px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota h3 {
  font-weight: 500;
  font-size: 14px;
  color: #9b9b9b;
  margin: 1rem 0;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding {
  width: 100%;
  padding: 0 2rem;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .wrapper-list {
  overflow-y: scroll;
  height: 250px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .wrapper-list .empty {
  text-align: center;
  width: auto;
  font-size: 12px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .wrapper-list .skeleton-tambah-anggota {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .wrapper-list .skeleton-tambah-anggota div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container {
  background-color: #ffffff;
  border-radius: 7.5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 2rem;
  z-index: 999;
  overflow-y: scroll;
  max-height: 200px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-outer-item {
  width: 100%;
  border: 1px solid #eceef2;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-item.not-found div {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 40px;
  width: 390px;
  margin: 0 auto;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-item div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-item img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-item p {
  font-size: 12px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-item span {
  font-size: 10px;
  color: #9b9b9b;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .padding .dropdown-container .dropdown-item button {
  outline: none;
  border: none;
  width: 75px;
  height: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota input[type=text] {
  width: 100%;
  height: 37px;
  border-radius: 5px;
  background-color: #f3f3f3;
  padding-left: 1rem;
  border: none;
  outline: none;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota h4 {
  font-weight: 500;
  font-size: 12px;
  -ms-flex-item-align: start;
      align-self: start;
  margin: 1rem 0;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .container-anggota {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .container-anggota img {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  margin-left: 1rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .container-anggota label {
  font-weight: 500;
  font-size: 10px;
  cursor: pointer;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .container-anggota .custom-checkbox {
  width: 20px;
  height: 20px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .container-anggota .custom-checkbox::after {
  width: 20px;
  height: 20px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .container-anggota .custom-checkbox::before {
  width: 7.5px;
  height: 7.5px;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .wrappper-btn {
  width: 100%;
  height: 80px;
  background-color: #f9f9f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  padding-right: 1rem;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .wrappper-btn button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .wrappper-btn button:nth-child(1) {
  background-color: #ff4530;
}
.pengaturan .detail-kategori-karyawan .bg-modal .modal-tambah-anggota .wrappper-btn button:nth-child(2) {
  background-color: #3661eb;
}
.pengaturan .detail-kategori-karyawan .alert-modal {
  width: 350px;
  height: 225px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.pengaturan .detail-kategori-karyawan .alert-modal h1 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 1rem;
  margin-right: auto;
}
.pengaturan .detail-kategori-karyawan .alert-modal p {
  font-weight: 500;
  font-size: 14px;
  margin-right: auto;
}
.pengaturan .detail-kategori-karyawan .alert-modal div {
  margin-top: auto;
  background-color: #f9f9f9;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 1rem;
  padding: 0 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.pengaturan .detail-kategori-karyawan .alert-modal button {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.pengaturan .detail-kategori-karyawan .alert-modal button:nth-child(1) {
  background-color: #ff4530;
}
.pengaturan .detail-kategori-karyawan .alert-modal button:nth-child(2) {
  background-color: #3661eb;
}
.pengaturan .batas-waktu {
  width: 100%;
  height: 50%;
  position: relative;
}
.pengaturan .batas-waktu h1 {
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 2rem;
}
.pengaturan .batas-waktu .containers-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}
.pengaturan .batas-waktu .containers-input .container-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.pengaturan .batas-waktu .containers-input .container-input label {
  margin-bottom: 0.5rem;
}
.pengaturan .batas-waktu .containers-input .container-input div {
  position: relative;
}
.pengaturan .batas-waktu .containers-input .container-input input {
  width: 187px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 8px;
  outline: none;
  padding: 1rem;
  font-weight: 400;
  font-size: 14px;
  color: #5a6474;
}
.pengaturan .batas-waktu .containers-input .container-input .format {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 14px;
  color: #9b9b9b;
  padding-right: 1rem;
}
.pengaturan .batas-waktu .containers-input .container-input .error__max {
  color: #ff4530;
  font-size: 12px;
}
.pengaturan .batas-waktu .containers-input .container-input .alert {
  font-size: 12px;
}
.pengaturan .batas-waktu .wrapper-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
}
.pengaturan .batas-waktu .wrapper-btn button {
  width: 125px;
  height: 40px;
  cursor: pointer;
}
.pengaturan .batas-waktu .wrapper-btn .edit-btn {
  background-color: #ffd233;
  color: black;
}
.pengaturan .batas-waktu .wrapper-btn .simpan-btn {
  background-color: #3661eb;
  color: white;
}
.pengaturan .batas-waktu .wrapper-btn .batal-btn {
  background-color: #ff4530;
  color: white;
}

.bg-navbar {
  width: 100vw;
  height: 100px;
  background-color: white;
  border: 1px solid #ebf0fe;
}

.wrapper-navbar {
  width: 1440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
}
.wrapper-navbar .wrapper-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80%;
  gap: 1.5rem;
  border-right: 1px solid #ebf0fe;
}
.wrapper-navbar .wrapper-logo img {
  width: 65px;
  height: 65px;
}
.wrapper-navbar .wrapper-logo h1 {
  font-size: 16px;
  font-weight: 500;
}

.wrapper-sidebar {
  width: 300px;
  position: relative;
}
.wrapper-sidebar .wrapper-sidebar-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  gap: 0.5rem;
}
.wrapper-sidebar .wrapper-sidebar-top::after {
  content: "";
  width: 100%;
  border-top: 2px solid #ECECEC;
  margin-top: 1rem;
}
.wrapper-sidebar .sidebar-container,
.wrapper-sidebar .keluar-pengaturan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  text-decoration: none;
  width: 300px;
  height: 36px;
  padding-left: 0.5rem;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-weight: 500;
  font-size: 14px;
  color: #5a6474;
  text-decoration: none;
}
.wrapper-sidebar .sidebar-container:hover,
.wrapper-sidebar .keluar-pengaturan:hover {
  background: #ececec;
  border-radius: 7.5px;
}
.wrapper-sidebar .sidebar-container.active {
  background: #ececec;
  border-radius: 7.5px;
}
.wrapper-sidebar .keluar-pengaturan {
  color: #ff4530;
  margin-top: 1rem;
}